//For history quotes provider

import { PROVIDER_QUOTE_ERROR, GET_PROVIDERS_QUOTE, GET_PROVIDER_QUOTES } from 'src/actions/types'

const initialState = {
  providers: {
    data: [],
    current_page: 1,
  },
  quotes: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  filtersQuotes: {},
  loadingQuotes: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PROVIDERS_QUOTE:
      return {
        ...state,
        providers: payload,
        filters: payload.filters,
        loading: false,
      }

    case GET_PROVIDER_QUOTES:
      return {
        ...state,
        quotes: payload,
        filtersQuotes: payload.filters,
        loadingQuotes: false,
      }

    case PROVIDER_QUOTE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        loadingQuotes: false,
      }
    default:
      return state
  }
}
