// For module that providers has

import { QUOTE_ERROR_PROVIDER, GET_QUOTES_PROVIDER } from 'src/actions/types'

const initialState = {
  quotes: {
    data: [],
    current_page: 1,
  },
  filters: {},
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_QUOTES_PROVIDER:
      return {
        ...state,
        quotes: payload,
        filters: payload.filters,
        loading: false,
        error: {},
      }

    case QUOTE_ERROR_PROVIDER:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
