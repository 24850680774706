import {
  GET_USERS,
  GET_USERS_PROVIDERS,
  ADD_USER,
  ADD_USER_PROVIDER,
  UPDATE_USER_PROVIDER,
  UPDATE_USER,
  DELETE_USER,
  USER_ERROR,
  GET_RECEIVERS,
  DELETE_USER_PROVIDER,
} from 'src/actions/types'

const initialState = {
  users: {
    data: [],
    current_page: 1,
  },
  usersProviders: {
    data: [],
    current_page: 1,
  },
  loadingProviders: true,
  loading: true,
  error: {},
  receiversQuote: [],
  loadingReceivers: true,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
      }
    case GET_USERS_PROVIDERS:
      return {
        ...state,
        usersProviders: payload,
        loadingProviders: false,
      }
    case ADD_USER:
      return {
        ...state,
        users: { data: [payload, ...state.users.data] },
        loading: false,
      }
    case ADD_USER_PROVIDER:
      return {
        ...state,
        usersProviders: { data: [payload, ...state.usersProviders.data] },
        loadingProviders: false,
      }
    case UPDATE_USER: {
      let index = state.users.data.findIndex((user) => user.id === payload.id)
      state.users.data[index] = { ...state.users.data[index], ...payload }
      return {
        ...state,
        users: { data: [...state.users.data], ...state.users },
        loading: false,
      }
    }
    case UPDATE_USER_PROVIDER: {
      let index = state.usersProviders.data.findIndex((user) => user.id === payload.id)
      state.usersProviders.data[index] = { ...state.usersProviders.data[index], ...payload }
      return {
        ...state,
        usersProviders: { data: [...state.usersProviders.data], ...state.usersProviders },
        loadingProviders: false,
      }
    }
    case DELETE_USER:
      return {
        ...state,
        users: { data: state.users.data.filter((user) => user.id !== payload) },
        loading: false,
      }
    case DELETE_USER_PROVIDER:
      return {
        ...state,
        usersProviders: { data: state.usersProviders.data.filter((user) => user.id !== payload) },
        loadingProviders: false,
      }
    case GET_RECEIVERS:
      return {
        ...state,
        receiversQuote: payload,
        loadingReceivers: false,
      }
    case USER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}
